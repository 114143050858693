<header label="Beneficiários">
  <default-pill #limitDateTooltip="ngbTooltip" [disabled]="true"
    [label]="'Data limite de movimentação: ' + limitDateForMoving" data-placement="bottom" [ngbTooltip]="tooltipContent"
    class="ngb-tooltip-dark" placement="bottom" tooltipClass="limit-date-tooltip"></default-pill>
</header>

<ng-template #tooltipContent>
  <div class="tooltip-container" fxLayout="row" fxLayoutAlign="start center">
    <span>Sua data limite está aqui! Lembre-se sempre dela antes de realizar
      qualquer movimentação.</span>
  </div>
</ng-template>

<div class="cooperator-page-container" fxLayout="column" fxLayoutGap="2vmin">
  <default-tabs [(activeTab)]="activeTab" (activeTabChange)="listCollaboratorsBasedOnTab()">
    <default-tab-item>
      <default-tab-label>Visão geral</default-tab-label>
      <default-tab-body>
        <div *ngIf="isTableView; then content; else other_content"></div>
        <ng-template #content>
          <div fxLayout="column">
            <form class="cooperator-form-group" [formGroup]="collaboratorSearchingFormGroup" fxFlex>
              <div fxLayout="row" fxFlex>
                <search-input fxFlex [timeToDebounce]="1000" label="Pesquisar beneficiário"
                  (valueChanges)="listCollaborators()" formControlName="collaborator"
                  placeholder="Digite o nome ou CPF do beneficiário"></search-input>
              </div>
            </form>

            <div fxLayout="column">
              <cooperator-table [actionMode]="type.DEFAULT" [dataSource]="filteredItems" [searchString]="
                  collaboratorSearchingFormGroup.get('collaborator')?.value
                " [(loading)]="onLoading" (onButtonClicked)="collaboratorActionClicked($event)"
                labelCancelRequest="Cancelamento solicitado em" [maxRowsLoaded]="5"></cooperator-table>

              <div *ngIf="filteredItems?.length" fxFlex fxLayoutAlign="end end"
              [ngClass]="{'offset-paginator-blipchat-opened': !!blipService.checkIfHasBlipChatOpened()}">
              <default-paginator itemLabel="beneficiários" [(total)]="totalRows" [pageNumber]="pageNumber"
                (onPageChange)="pageChanged($event)" [maxPerPage]="maxPerPage"></default-paginator>
            </div>
            </div>
          </div>
        </ng-template>
        <ng-template #other_content>
          <div class="cooperator-form-group" fxLayout="column">
            <quarternary-link-button label="Voltar para a lista" icon="../../../assets/svgs/arrow_back.svg"
              (onClick)="backToTableView()"></quarternary-link-button>

            <div class="card-cooperator">
              <div style="margin: 40px;">
                <default-pill class="status-cancel-request" [disabled]="true" *ngIf="collaboratorInfo.nextInvoice"
                  label="{{collaboratorInfo.includeInformation}}">
                </default-pill>
              </div>

              <div fxLayout="row" class="external-container-cooperator">
                <div class="container-divide" fxFlex="50" fxLayout="column">


                  <div fxLayout="column">
                    <div class="line-bottom" fxLayout="row" fxLayoutAlign="space-between center">


                      <p class="title-label-cooperator">Dados gerais</p>
                      <div>
                        <tertiary-button icon="../../../assets/svgs/chat-icon.svg" (click)="openChat()"
                          label="Solicitar edição"></tertiary-button>
                      </div>
                    </div>

                    <div class="row-info-cooperator" style="margin-top: 15px" fxLayout="row">
                      <p class="bold-label-cooperator-info">Nome:</p>
                      <p class="bold-value-cooperator-info">
                        {{ collaboratorInfo?.nome | titlecase }}
                      </p>
                    </div>

                    <div class="row-info-cooperator" fxLayout="row">
                      <p class="bold-label-cooperator-info">CPF:</p>
                      <p class="bold-value-cooperator-info">
                        {{ collaboratorInfo?.cpf | cpf }}
                      </p>
                    </div>

                    <div class="row-info-cooperator" fxLayout="row">
                      <p class="bold-label-cooperator-info">Salário:</p>
                      <p class="bold-value-cooperator-info" *ngIf="collaboratorInfo?.salary">
                        R$ {{ collaboratorInfo?.salary }}
                      </p>
                      <p class="bold-value-cooperator-info" *ngIf="!collaboratorInfo?.salary">
                        não informado
                      </p>
                    </div>

                    <div class="row-info-cooperator" fxLayout="row">
                      <p class="bold-label-cooperator-info">Nome da mãe:</p>
                      <p class="bold-value-cooperator-info">
                        {{ collaboratorInfo?.nomeMae | titlecase }}
                      </p>
                    </div>

                    <div class="row-info-cooperator" fxLayout="row">
                      <p class="bold-label-cooperator-info">
                        Data de nascimento:
                      </p>
                      <p class="bold-value-cooperator-info">
                        {{ collaboratorInfo?.dataNascimento }}
                      </p>
                    </div>

                    <div class="row-info-cooperator" fxLayout="row">
                      <p class="bold-label-cooperator-info">Sexo:</p>
                      <p class="bold-value-cooperator-info">
                        {{ collaboratorInfo?.sexo | titlecase }}
                      </p>
                    </div>
                  </div>

                </div>

                <div class="container-divide" fxFlex="50" fxLayout="column">
                  <div fxLayout="column">



                    <div class="line-bottom" fxLayout="row" fxLayoutAlign="space-between center">
                      <p class="title-label-cooperator">Contatos</p>
                      <div>
                        <tertiary-button (onClick)="openModalEditContact()" icon="../../../assets/svgs/edit-icon.svg"
                          label="Editar">
                        </tertiary-button>
                      </div>
                    </div>

                    <div class="row-info-cooperator" style="margin-top: 15px" fxLayout="row">
                      <p class="bold-label-cooperator-info">E-mail:</p>
                      <p class="bold-value-cooperator-info">
                        {{ collaboratorInfo?.email || "Não cadastrado" }}
                      </p>
                    </div>

                    <div class="row-info-cooperator" fxLayout="row">
                      <p class="bold-label-cooperator-info">
                        Celular (Whatsapp):
                      </p>
                      <p class="bold-value-cooperator-info">
                        {{
                        collaboratorInfo?.celular || "Não cadastrado" | phone
                        }}
                      </p>
                    </div>

                    <div class="row-info-cooperator" fxLayout="row">
                      <p class="bold-label-cooperator-info">Telefone:</p>
                      <p class="bold-value-cooperator-info">
                        {{
                        collaboratorInfo?.telefone || "Não cadastrado" | phone
                        }}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="card-cooperator">
              <div fxLayout="row" class="external-container-cooperator">
                <div class="container-divide" fxFlex="100" fxLayout="column">
                  <div fxLayout="column">
                    <div class="line-bottom" fxLayout="row" fxLayoutAlign="space-between center">
                      <p class="title-label-cooperator">Plano</p>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                      <span class="name-title semi-bold p3">Plano ativo: </span>
                      <p class="bold-value-cooperator-info"> {{this.collaboratorInfo.plan}} </p>

                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                      <span class="name-title semi-bold p3">Valor atual: </span>
                      <p class="bold-value-cooperator-info"> {{this.collaboratorInfo.price}}/mensal </p>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div *ngIf="this.collaboratorInfo.typeBeneficiary === 'Dependente'" class="card-cooperator">
              <div fxLayout="row" class="external-container-cooperator">
                <div class="container-divide" fxFlex="100" fxLayout="column">
                  <div fxLayout="column">
                    <div class="line-bottom" fxLayout="row" fxLayoutAlign="space-between center">
                      <p class="title-label-cooperator">Titular vinculado</p>
                    </div>
                    <dependent-table [actionMode]="type.DEFAULT"
                      [dataSource]="[this.collaboratorsDependentsInfo?.titular]"
                      (onButtonClicked)="collaboratorAction($event)" labelCancelRequest="" [loading]="false"
                      [maxRowsLoaded]="5">
                    </dependent-table>
                  </div>
                </div>


              </div>
            </div>

            <div *ngIf="this.collaboratorInfo.typeBeneficiary === 'Colaborador'" class="card-cooperator">
              <div fxLayout="row" class="external-container-cooperator">
                <div class="container-divide" fxFlex="100" fxLayout="column">
                  <div fxLayout="column">
                    <div class="line-bottom" fxLayout="row" fxLayoutAlign="space-between center">
                      <p class="title-label-cooperator">Dependentes</p>
                      <div>
                        <div class="container-button-edit-contact">
                          <default-button label="Incluir dependente" icon="../../../assets/svgs/edit-icon.svg"
                            (onClick)="this.newDependent()">
                          </default-button>
                        </div>
                      </div>
                    </div>
                    <dependent-table [actionMode]="type.DEFAULT"
                      [dataSource]="this.collaboratorsDependentsInfo?.dependentes || []"
                      (onButtonClicked)="collaboratorAction($event)" labelCancelRequest="" [loading]="false"
                      [maxRowsLoaded]="5">
                    </dependent-table>
                  </div>
                </div>


              </div>
            </div>

            <span class="span-edit" (click)="openChat()">
              <span class="click-here-span-edit">Clique aqui</span> para
              solicitar a edição dos dados gerais
            </span>
          </div>
        </ng-template>

      </default-tab-body>
    </default-tab-item>

    <default-tab-item>
      <default-tab-label>Inclusão</default-tab-label>
      <default-tab-body>
        <div *ngIf='plansInfoLoaded && !hasHolderPlans' fxLayout="column" fxLayoutAlign="center center">
          <div class="container default-border-radius" fxLayout="column" fxLayoutAlign="center center">
            <div class="title-container">
              <p class="bold p2 text-center">
                Inclusão de vidas indisponível
              </p>
            </div>
            <p class="regular small text-center">
              {{messagemErroInclusaoVidas}}
            </p>
          </div>
        </div>
        <div *ngIf='plansInfoLoaded && hasHolderPlans' fxLayout="column" fxLayoutAlign="center center">
          <div class="container default-border-radius" fxLayout="column" fxLayoutAlign=" center">
            <div class="title-and-subtitle-container">
              <div class="title-container">
                <p class="bold p2 text-center">
                  Como você deseja realizar a inclusão?
                </p>
              </div>
              <p class="regular small text-center">
                Individualmente - faça a inclusão de uma pessoa por vez<br />Por
                planilha - solicite a inclusão de mais de 10 pessoas de uma vez
              </p>
            </div>
            <div class="main-button">
              <default-button icon="../../../assets/svgs/profile-icon.svg" label="Individualmente" class="button"
                (onClick)="openChooseBeneficiaryTypeModal()"></default-button>
            </div>
            <tertiary-button icon="../../../assets/svgs/document-icon.svg" label="Por planilha"
              (onClick)="openSpreadsheetInclusionModal()" class="button"></tertiary-button>
          </div>
        </div>
      </default-tab-body>
    </default-tab-item>

    <default-tab-item>
      <default-tab-label>Solicitar cancelamento</default-tab-label>
      <default-tab-body>
        <form class="cooperator-form-group" fxLayout="column" [formGroup]="collaboratorSearchingFormGroup" fxFlex>
          <p class="label-input-search semi-bold p4 spacing secondary-light">
            Pesquisar beneficiário
          </p>
          <div fxLayout="row" fxFlex fxLayoutGap="1%" fxLayoutAlign="space-between end">
            <search-input fxFlex [timeToDebounce]="1000" (valueChanges)="listCollaborators()"
              formControlName="collaborator" placeholder="Digite o nome ou CPF do beneficiário"></search-input>
            <!-- // TODO: Reincluir quando a carteirinha estiver funcionando (S4E) -->
            <!-- <div fxLayout="column" style="height: 100%">
              <extra-button
                icon="../../../../assets/svgs/spreadsheet-icon.svg"
                iconOrientation="start"
                [typeStyle]="extraButtonType.GREY_OUTLINE"
                label="Solicitar por planilha"
                (onClick)="requestCancelBySpreadsheet()"
              ></extra-button>
            </div> -->
          </div>
        </form>
        <div fxLayout="column">
          <cooperator-table [actionMode]="type.DELETE" [dataSource]="filteredItems" [searchString]="

              collaboratorSearchingFormGroup.get('collaborator')?.value

            " [(loading)]="onLoading" (onButtonClicked)="openCancelConfirmationModal($event)"
            labelCancelRequest="Cancelamento solicitado em" [maxRowsLoaded]="5"></cooperator-table>

          <div *ngIf="filteredItems.length" fxFlex fxLayoutAlign="end end">
            <default-paginator itemLabel="beneficiários" [(total)]="totalRows" [pageNumber]="pageNumber"
              (onPageChange)="pageChanged($event)" [maxPerPage]="maxPerPage"></default-paginator>
          </div>
        </div>
      </default-tab-body>
    </default-tab-item>
  </default-tabs>
</div>

<ng-template #stepper>
  <stepper-generic id="cdkStepper" #cdkStepper [linear]="isLinear">
    <cdk-step>
      <form [formGroup]="firstStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxFlex>
          <input [hidden]="true" />
          <p class="bold p2">Dados gerais</p>
          <div fxLayout="column" class="input-container">
            <default-input formControlName="cpf" label="CPF" placeholder="Informe o CPF do colaborador"
              mask="000.000.000-00"></default-input>
          </div>
          <div fxLayout="column" class="input-container">
            <default-input formControlName="fullName" label="Nome completo"
              placeholder="Informe o nome completo do colaborador"></default-input>
          </div>
          <div fxLayout="column" class="input-container">
            <default-input formControlName="salary" label="Salário"
              placeholder="Informe o valor do salário do colaborador" type="text" prefix="R$ " mask="separator"
              thousandSeparator=".">
            </default-input>
          </div>
          <div fxLayout="column" class="input-container">
            <default-input *ngIf="requiresRegistration" formControlName="registration" label="Matrícula"
              placeholder="Informe a matrícula do colaborador"></default-input>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
          <default-button fxFlex="48" [disabled]="firstStepForm.invalid" label="Avançar" (onClick)="nextStep()">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="secondStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="stepper-scroll-content">
            <p class="bold p2">Dados gerais</p>
            <div class="input-container">
              <default-input formControlName="birthday" label="Data de nascimento" [dropSpecialCharacters]="false"
                mask="00/00/0000" placeholder="DD/MM/AAAA"></default-input>
            </div>
            <div class="input-container">
              <default-input formControlName="fullMotherName" label="Nome completo da mãe"
                placeholder="Informe o nome completo da mãe do colaborador"></default-input>
            </div>
            <div class="input-container">
              <radio-group #gender (selectChange)="genderValueChanges($event)" title="Sexo"
                [options]="radioButtonGenderOptions" subtitle="(para fins médicos)"></radio-group>
            </div>

          </div>
          <div fxLayout="row" fxLayoutAlign="space-between end">
            <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep()"></tertiary-button>
            <default-button fxFlex="48" [disabled]="secondStepForm.invalid" label="Avançar" (onClick)="nextStep()">
            </default-button>
          </div>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="thirdStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="stepper-scroll-content">
            <p class="bold p2">Contatos</p>
            <div class="input-container">
              <default-input formControlName="email" label="E-mail" placeholder="Informe um e-mail do colaborador">
              </default-input>
            </div>
            <div class="input-container">
              <default-input formControlName="mobilePhoneNumber" label="Celular" informativeLabel="(WhatsApp)"
                mask="(00) 0 0000 0000" placeholder="(DD) - ---- -----"></default-input>
            </div>
            <div class="input-container">
              <default-input formControlName="telephoneNumber" label="Telefone (opcional)" mask="(00) 0000 0000"
                placeholder="(DD) ---- -----"></default-input>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between end">
            <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep()"></tertiary-button>
            <default-button fxFlex="48" [disabled]="thirdStepForm.invalid" label="Avançar" (onClick)="nextStep()">
            </default-button>
          </div>
        </div>
      </form>
    </cdk-step>
    <!-- <cdk-step>
      <form [formGroup]="choosePlanForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div>
            <p class="bold p2">Opções de plano</p>
            <div class="subtitle-spacing">
              <p ngif class="semi-bold p3">
                Selecione um dos planos disponíveis
              </p>
              <radio-plan-selection #plan formControlName="selectedPlan" [options]="cctPlans"
                (selectChange)="selectPlan($event)"></radio-plan-selection>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep()"></tertiary-button>
          <default-button fxFlex="48" [disabled]="choosePlanForm.invalid" label="Avançar" (onClick)="nextStep()">
          </default-button>
        </div>
      </form>
    </cdk-step> -->


    <cdk-step>
      <form [formGroup]="choosePlanForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div>
            <p class="bold p2">Opções de plano</p>
            <div class="subtitle-spacing">
              <p ngif class="semi-bold p3">
                Selecione um dos planos disponíveis
              </p>
              <radio-plan-selection #plan [options]="cctPlans" (selectChange)="selectPlan($event)">
              </radio-plan-selection>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep()"></tertiary-button>
          <default-button fxFlex="48" [disabled]="choosePlanForm.invalid" label="Avançar" (onClick)="nextStep()">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="fourthStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div>
            <p class="bold p2">Inclusão na fatura</p>
            <div class="subtitle-spacing">
              <ng-container *ngIf="this.hasCCTCompany; else elseTemplate">
                <p ngif class="semi-bold p3">Competência para inclusão</p>
              </ng-container>
              <ng-template #elseTemplate>
                <p ngif class="semi-bold p3">Selecione uma competência</p>
              </ng-template>

              <radio-group #accrualDate (selectChange)="accrualDateValueChanges($event)"
                (optionSelected)="changeSelectedAccrualDate($event)" [options]="radioButtonAccrualDatesOptions"
                fxLayout="column"></radio-group>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep()"></tertiary-button>
          <default-button fxFlex="48" [disabled]="fourthStepForm.invalid" label="Avançar" (onClick)="nextStep()">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
        <div class="stepper-scroll-content">
          <span-warning disclaimer="Confira as informações:"
            description="após salvar o cadastro, somente será possível editar alguns dados entrando em contato com a gente.">
          </span-warning>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Dados gerais</p>
            <hr class="hr-separator" />
          </div>
          <div>
            <p class="semi-bold p4 secondary-light-text-color">
              CPF:
              <span class="regular p4 gray-dark-text-color">{{
                this.firstStepForm.get("cpf")?.value | cpf
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Nome completo:
              <span class="regular p4 gray-dark-text-color">
                {{ this.firstStepForm.get("fullName")?.value }}
              </span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Salário:
              <span class="regular p4 gray-dark-text-color">
                {{ this.firstStepForm.get("salary")?.value | price }}
              </span>
            </p>
            <p *ngIf="requiresRegistration" class="semi-bold p4 secondary-light-text-color">
              Matrícula:
              <span class="regular p4 gray-dark-text-color">
                {{ this.firstStepForm.get("registration")?.value }}
              </span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Nome da mãe:
              <span class="regular p4 gray-dark-text-color">{{
                this.secondStepForm.get("fullMotherName")?.value
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Data de nascimento:
              <span class="regular p4 gray-dark-text-color">{{
                this.secondStepForm.get("birthday")?.value
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Sexo:
              <span class="regular p4 gray-dark-text-color">{{
                this.secondStepForm.get("gender")?.value
                }}</span>
            </p>
          </div>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Contatos</p>
            <hr class="hr-separator" />
          </div>
          <div>
            <p class="semi-bold p4 secondary-light-text-color">
              E-mail:
              <span class="regular p4 gray-dark-text-color">{{
                this.thirdStepForm.get("email")?.value
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Celular (WhatsApp):
              <span class="regular p4 gray-dark-text-color">{{
                this.thirdStepForm.get("mobilePhoneNumber")?.value | phone
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Telefone:
              <span class="regular p4 gray-dark-text-color">{{
                (this.thirdStepForm.get("telephoneNumber")?.value | phone) ||
                "(DD) - ---- -----"
                }}</span>
            </p>
          </div>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Plano</p>
            <hr class="hr-separator" />
            <div>
              <p class="semi-bold p4 secondary-light-text-color">
                {{choosePlanForm.get('planName')?.value}}
              </p>
              <p class="regular p3 gray-dark-text-color" style="color: #928F8F !important">
                {{ choosePlanForm.get('planPrice')?.value }} <span style="color: #928F8F" class="regular small">/{{
                  choosePlanForm.get('planFrequency')?.value }}</span>
              </p>
            </div>
          </div>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Inclusão na fatura</p>
            <hr class="hr-separator" />
          </div>
          <div>
            <p class="semi-bold p4 secondary-light-text-color">
              {{ selectedAccrualDate?.description }} ({{
              selectedAccrualDate?.label
              }})
            </p>
            <p class="regular p4 subtitle gray-dark-text-color">
              {{ selectedAccrualDate?.subdescription }} {{ dateCanUsePlan || cancellationDate }}
            </p>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between end">
        <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep()"></tertiary-button>
        <default-button fxFlex="48" [disabled]="onLoading" label="Salvar inclusão" (onClick)="saveInclusion()">
        </default-button>
      </div>
    </cdk-step>
  </stepper-generic>
</ng-template>

<ng-template #individualCancelStepper>
  <stepper-generic id="individualCancelCdkStepper" #individualCancelCdkStepper [linear]="isLinear">
    <cdk-step>
      <div fxLayout="column" fxLayoutAlign="space-between" fxFlex>
        <p class="bold p2 title-color">Solicitação de cancelamento de</p>
        <p class="bold p2 collaborator-color-name">
          {{ this.collaboratorName }}
        </p>
        <form [formGroup]="individualCancelFirstStepForm" fxLayout="column" fxLayoutAlign="space-between" fxFlex>
          <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
            <div class="stepper-scroll-content">
              <div class="subtitle-spacing">
                <div class="input-container">
                  <default-input formControlName="fullName" label="Nome de quem está solicitando"
                    placeholder="Informe o seu nome"></default-input>
                </div>
                <div class="input-container">
                  <default-input formControlName="email" label="E-mail de quem está solicitando"
                    placeholder="Informe o seu e-mail"></default-input>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end">
            <default-button fxFlex="48" [disabled]="individualCancelFirstStepForm.invalid" label="Avançar"
              (onClick)="nextStepIndividualCancel()"></default-button>
          </div>
        </form>
      </div>
    </cdk-step>

    <cdk-step>
      <p class="bold p2 title-color">Solicitação de cancelamento de</p>
      <p class="bold p2 collaborator-color-name">
        {{ this.collaboratorName }}
      </p>
      <form [formGroup]="individualCancelSecondStepForm" fxLayout="column" fxLayoutAlign="space-between" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="subtitle-spacing">
            <p class="semi-bold p3">Selecione uma competência</p>
            <radio-group #individualCancelRadioGroup id="individualCancelRadioGroup"
              (selectChange)="accrualDateValueChanges($event)" (optionSelected)="
                accrualDateValueChangesOnIndividualCancel($event)
              " [options]="radioButtonAccrualDatesOptionsIndividualCancel" fxLayout="column"></radio-group>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStepIndividualCancel()"></tertiary-button>
          <default-button fxFlex="48" [disabled]="individualCancelSecondStepForm.invalid" label="Avançar"
            (onClick)="nextStepIndividualCancel()"></default-button>
        </div>
      </form>
    </cdk-step>

    <cdk-step>
      <p class="bold p2 title-color">Solicitação de cancelamento de</p>
      <p class="bold p2 collaborator-color-name">
        {{ this.collaboratorName }}
      </p>
      <form [formGroup]="individualCancelThirdStepForm" fxLayout="column" fxLayoutAlign="space-between" fxFlex>
        <div class="modal-step stepper-scroll-content" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="subtitle-spacing">
            <default-dropdown [options]="this.cancelationReasons" (selectedChange)="
                changeShowDragAndDropOnCancelationReason($event)
              " placeholder="Selecione um motivo" label="Motivo do cancelamento" formControlName="cancellationReason">
            </default-dropdown>
            <drag-and-drop-files label="Anexar arquivo" *ngIf="showDragAndDropOnCancelationReason"
              alert='<p class="small attention"><span class="bold">Atenção:</span> em casos de <span class="bold">demissão, afastamento</span> ou <span class="bold">óbito,</span> você também precisa enviar o documento que comprove o motivo do cancelamento.</p>'
              description="Formatos JPEG, PNG, MP4, PDF, docx, Excel ou CSV e com até 100mb"
              [allowedFilesExtension]="ACCEPT_FILES_EXTENSIONS_CANCELLATION" [allowedFileSizeInMB]="ALLOW_FILE_SIZE"
              [(files)]="filesList" (unsupportedFile)="
                unsupportedFile($event, 'individualCancelModalClass')
              " (onDelete)="deleteFile($event)"></drag-and-drop-files>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button class="footer" fxFlex="48" label="Voltar" (onClick)="previousStepIndividualCancel()">
          </tertiary-button>
          <default-button class="footer" fxFlex="48" [disabled]="
              individualCancelSecondStepForm.invalid ||
              onLoading ||
              (showDragAndDropOnCancelationReason && filesList.length == 0)
            " label="Enviar solicitação" (onClick)="sendCancelSolicitation()"></default-button>
        </div>
      </form>
    </cdk-step>
  </stepper-generic>
</ng-template>

<ng-template #spreadsheetInclusionStepper>
  <stepper-generic #spreadsheetInclusionCdkStepper [linear]="isLinear">
    <cdk-step>
      <form [formGroup]="secondStepSpreadsheetInclusionForm" fxLayout="column" fxFlex fxLayoutAlign="space-between">
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxLayoutGap="1.5vmin">
          <div fxLayout="column" fxLayoutGap="2vmin">
            <p class="bold p2">Inclusão por planilha</p>

            <radio-group #spreadSheetRadioGroup id="spreadSheetRadioGroup" (selectChange)="
                accrualDateValueChangesSpreadsheetInclusion($event)
              " (optionSelected)="
                selectAccrualDateForSpreadsheetInclusion($event)
              " title="Selecione uma competência para a fatura" [(selected)]="selectedAccrualDateSpreadsheetInclusion"
              [options]="radioButtonAccrualDatesOptionsSpreadsheetInclusion" fxLayout="column"></radio-group>
          </div>
        </div>
        <div fxLayout="row-reverse" fxLayoutAlign="space-between end">
          <default-button class="footer" fxFlex="48" [disabled]="secondStepSpreadsheetInclusionForm.invalid"
            label="Avançar" (onClick)="nextStepS(spreadsheetInclusionCdkStepper)"></default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="secondStepSpreadsheetInclusionForm" fxLayout="column" fxFlex fxLayoutAlign="space-between">
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxLayoutGap="1.5vmin">
          <div fxLayout="column">
            <p class="bold p2">Opções de plano</p>
            <span-warning class="mt-4" disclaimer="Atenção:"
              description="todos os beneficiários da planilha serão inclusos conforme o(s) plano(s) selecionado(s) abaixo.">
            </span-warning>
            <div class="scrolling-container">
              <div class="mt-4 mb-2">
                <p class="semi-bold p4"> Selecione o(s) tipo(s) beneficiário(s) que estão na planilha</p>
              </div>
              <div fxLayout="row" class="mb-3">
                <default-checkbox fxFlex="40" (checked)="checkHolderCheckbox($event)"
                  label="Titular"></default-checkbox>
                <default-checkbox (checked)="checkDependentCheckbox($event)" [disabled]="!hasDependentPlans"
                  label="Dependente"></default-checkbox>
              </div>

              <div *ngIf="showHolderPlans" class="subtitle-spacing">
                <p class="semi-bold p4">Titular</p>
                <p class="regular small text-color-gray">Selecione um dos planos disponíveis</p>
                <radio-plan-selection #plan [options]="holderSpreadsheetPlans" (selectChange)="selectPlan($event)">
                </radio-plan-selection>
              </div>
              <div class="mt-2 pt-2" *ngIf="showDependentPlans">
                <p class="semi-bold p4">Dependente</p>
                <p class="regular small text-color-gray">Selecione um dos planos disponíveis</p>
                <radio-plan-selection #plan [options]="dependentSpreadsheetPlans"
                  (selectChange)="selectDependentPlan($event)">
                </radio-plan-selection>
              </div>
            </div>

          </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button class="footer" fxFlex="48" label="Voltar"
            (onClick)="previousStepS(spreadsheetInclusionCdkStepper)"></tertiary-button>
          <default-button class="footer" fxFlex="48"
            [disabled]="!(showHolderPlans || showDependentPlans) || (showHolderPlans && this.choosePlanForm.invalid) || (showDependentPlans && this.dependentChoosePlanForm.invalid)"
            label="Avançar" (onClick)="nextStepS(spreadsheetInclusionCdkStepper)"></default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <div fxLayout="column" fxFlex fxLayoutAlign="start" fxLayoutGap="1.5vmin">
        <p class="bold p2">Inclusão por planilha</p>
        <div class="stepper-scroll-content" fxLayout="column" fxFlex>
          <div fxLayout="column" fxLayoutGap="1.5vmin" style="max-height: 45vh">
            <div>
              <p class="semi-bold small color-gray-dark">
                <a target="_self" href="../../assets/spreadsheets/IMPORTAÇÃO.xlsx"
                  download="modelo_planilha_inclusão.xlsx" class="link-default bold">Baixe aqui a planilha modelo</a>
                e preencha todas as informações. Depois, envie o arquivo e
                clique para solicitar a inclusão.
              </p>
            </div>
            <drag-and-drop-files id="dragAndDropFiles" #dragAndDropFiles label="Anexar arquivo"
              description="Formatos Excel ou CSV e com até 100mb"
              [allowedFilesExtension]="ACCEPT_FILES_EXTENSIONS_CANCELLATION" [allowedFileSizeInMB]="ALLOW_FILE_SIZE"
              (filesChange)="filesIncluded($event)" [(files)]="filesListSpreadsheetInclusion" (unsupportedFile)="
                unsupportedFile($event, 'inclusionBySpreadsheetStepperModal')
              " (onDelete)="deleteSpreadsheetInclusionFile($event)" [isOptional]="false"></drag-and-drop-files>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between end">
        <tertiary-button class="footer" fxFlex="48" label="Voltar"
          (onClick)="previousStepS(spreadsheetInclusionCdkStepper)">
        </tertiary-button>
        <default-button class="footer" fxFlex="48" label="Salvar inclusão"
          [disabled]="!filesListSpreadsheetInclusion.length || onLoading"
          (onClick)="sendRequestInclusionBySpreadsheet(spreadsheetInclusionCdkStepper)">
        </default-button>
      </div>
    </cdk-step>
    <cdk-step>
      <div fxLayout="column" fxFlex fxLayoutAlign="space-between stretch" fxLayoutGap="1.5vmin">
        <div fxLayout="column" fxLayoutGap="1.5vmin">
          <p class="bold p2">Inclusão por planilha</p>
          <div fxLayout="column" fxLayoutGap="1.5vmin">
            <div class="bold small"> {{progressBar}}% carregado</div>

            <div class="progress progress-height">
              <div [ngStyle]="{'background-color': colorProgress,'width': progressBar + '%'}" class="progress-bar">
              </div>
            </div>
          </div>
          <div class="card-beneficiary" fxLayout="column">
            <div class="semi-bold p4" fxLayoutAlign="space-between">
              <div>Beneficiários inclusos:</div>
              <div>{{numberOfRegisteredBeneficiaries}}</div>
            </div>
          </div>
          <div class="card-beneficiary" fxLayout="column" fxLayoutAlign=" center">
            <div class="card-beneficiary-error">
              <div class="pb-3 semi-bold p4">Beneficiários com erro: {{numberOfBeneficiariesWithError}}</div>

              <div class="pb-3" *ngIf="this.beneficiariesWithError.length > 0">
                <span class="attention bold"> Atenção:</span> <span class="attention-text-description">
                  os beneficiários com erro não estão cadastrados. Recomendamos fazer os ajustes <span
                    class="bold">agora
                  </span> clicando em
                  “Reenviar planilha”. Ou ajuste depois clicando em “Finalizar inclusão”.
                </span>
              </div>

              <div *ngFor="let item of this.beneficiariesWithError" fxLayoutAlign="space-between">
                <div class="semi-bold small">{{item.name}}</div>
                <div class="regular small ">{{item.errorMessage}}</div>
              </div>
            </div>
          </div>
        </div>

        <div [fxLayout]="this.numberOfBeneficiariesWithError > 0 ? 'row' : 'row-reverse'"
          fxLayoutAlign="space-between end">
          <tertiary-button *ngIf="this.numberOfBeneficiariesWithError > 0" [disabled]="!doneInclusionBeneficiary"
            class="footer" fxFlex="48" label="Reenviar planilha"
            (onClick)="previousStepS(spreadsheetInclusionCdkStepper)">
          </tertiary-button>
          <default-button class="footer" fxFlex="48" label="Finalizar inclusões" [disabled]="!doneInclusionBeneficiary"
            (onClick)="this.conclusionInclusion()">
          </default-button>
        </div>
      </div>
    </cdk-step>
  </stepper-generic>
</ng-template>

<ng-template #modalEditContact>
  <form [formGroup]="editContactForm">
    <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none">
      <div>
        <p class="bold p2">Contatos</p>
        <div class="subtitle-spacing">
          <default-input formControlName="email" label="E-mail" placeholder="Digite seu e-mail"></default-input>

          <default-input formControlName="mobilePhone" label="Celular (Whatsapp)" placeholder="Digite seu celular"
            mask="00 00000-0000" [dropSpecialCharacters]="false"></default-input>

          <default-input formControlName="phone" label="Telefone (opcional)" placeholder="Digite seu telefone"
            mask="(00) 0000 0000" placeholder="(DD) ---- -----" [dropSpecialCharacters]="false"></default-input>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end end">
        <default-button class="footer" fxFlex="48" [disabled]="editContactForm.invalid" label="Salvar"
          (onClick)="editContact()"></default-button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #modalChooseBeneficiaryType>
  <form [formGroup]="beneficiaryTypeForm">
    <div style="padding: 0 5vmin">
      <p class="bold text-align-center">
        Qual o tipo de beneficiário que<br />
        você deseja incluir?
      </p>
      <card-beneficiary-type #cardBeneficiaryTypeRef [radioButtonBeneficiaryOptions]="beneficiaryOptions"
        (selectChange)="chooseBeneficiaryType($event)"></card-beneficiary-type>
      <div style="margin-top: 5vmin">
        <default-button label="Iniciar inclusão" [disabled]="beneficiaryTypeForm.invalid"
          (onClick)="helperOpenIndividualInclusionModal()">
        </default-button>
      </div>
    </div>
  </form>
</ng-template>


<ng-template #dependentInclusionStepper>
  <stepper-generic id="dependentInclusionCdkStepper" #dependentInclusionCdkStepper [linear]="isLinear">
    <cdk-step>
      <form [formGroup]="dependentInclusionFirstStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxFlex>
          <p class="bold p2">Inclusão de dependente</p>
          <div fxLayout="column" class="input-container">
            <search-input class="test" [timeToDebounce]="1000" [showInputReturn]="false" formControlName="holderCpf"
              label="Selecione o colaborador titular" placeholder="Informe o CPF do colaborador" mask="000.000.000-00"
              (valueChanges)="getActiveHolders($event)"></search-input>
            <p *ngIf="searchHolderErrorMessage" class="regular p3 gray-dark-text-color">
              {{searchHolderErrorMessage[0]}} <span
                class="semi-bold p3 gray-dark-text-color">{{searchHolderErrorMessage[1]}}</span></p>
            <card-beneficiary-type #cardBeneficiaryTypeRef *ngIf="holder" [radioButtonBeneficiaryOptions]="holder"
              (selectChange)="chooseHolder($event)"></card-beneficiary-type>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
          <default-button fxFlex="48" [disabled]="dependentInclusionFirstStepForm.invalid" label="Avançar"
            (onClick)="nextStep(dependentInclusionCdkStepper)">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="dependentInclusionSecondStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="stepper-scroll-content">
            <p class="bold p2">Dados gerais</p>
            <div class="input-container">
              <default-input [disabled]="true" [value]="dependentInclusionSecondStepForm.get('holderName')?.value"
                formControlName="holderName" label="Titular vinculado" placeholder=""></default-input>
            </div>
            <div class="input-container">
              <default-dropdown [options]="degreeOfKinshipOptions" formControlName="degreeOfKinship"
                label="Grau de parentesco com o titular" placeholder="Selecione uma opção"></default-dropdown>
            </div>
            <div class="input-container">
              <default-input (valueChanges)="changeBirthdayDependent($event)" formControlName="birthday"
                label="Data de nascimento" [dropSpecialCharacters]="false" mask="00/00/0000" placeholder="DD/MM/AAAA">
              </default-input>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between end">
            <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(dependentInclusionCdkStepper)">
            </tertiary-button>
            <default-button fxFlex="48" [disabled]="dependentInclusionSecondStepForm.invalid" label="Avançar"
              (onClick)="nextStep(dependentInclusionCdkStepper)">
            </default-button>
          </div>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="dependentInclusionThirdStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="stepper-scroll-content">
            <p class="bold p2">Dados gerais</p>
            <input [hidden]="true" />
            <div class="input-container">
              <default-input formControlName="cpf" label="CPF" mask="000.000.000-00"
                placeholder="Informe o CPF do dependente"></default-input>
            </div>
            <div fxLayout="column" class="input-container">
              <default-input formControlName="fullName" label="Nome completo"
                placeholder="Informe o nome completo do dependente"></default-input>
            </div>
            <div class="input-container">
              <default-input formControlName="fullMotherName" label="Nome completo da mãe"
                placeholder="Informe o nome completo da mãe do dependente"></default-input>
            </div>
            <div class="input-container">
              <radio-group #gender (selectChange)="dependentGenderValueChanges($event)" title="Sexo"
                [options]="radioButtonGenderOptions" subtitle="(para fins médicos)"></radio-group>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(dependentInclusionCdkStepper)">
          </tertiary-button>
          <default-button fxFlex="48" [disabled]="dependentInclusionThirdStepForm.invalid" label="Avançar"
            (onClick)="nextStep(dependentInclusionCdkStepper)">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="dependentInclusionFourthStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="stepper-scroll-content">
            <p class="bold p2">Contatos</p>
            <input [hidden]="true" />
            <div class="input-container">
              <default-input formControlName="mobilePhoneNumber" label="Celular" informativeLabel="(WhatsApp)"
                mask="(00) 0 0000 0000" placeholder="(DD) - ---- -----"
                [errorMessage]="'Número Inválido. Tente novamente'"></default-input>
            </div>
            <div class="input-container">
              <default-input formControlName="telephoneNumber" label="Telefone" informativeLabel="(opcional)"
                mask="(00) 0000 0000" placeholder="(DD) - ---- ----"
                [errorMessage]="'Número Inválido. Tente novamente'"></default-input>
            </div>
            <div class="input-container">
              <default-input informativeLabel="(opcional)" formControlName="email" label="E-mail"
                placeholder="Informe um e-mail">
              </default-input>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between end">
            <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(dependentInclusionCdkStepper)">
            </tertiary-button>
            <default-button fxFlex="48" [disabled]="dependentInclusionFourthStepForm.invalid" label="Avançar"
              (onClick)="nextStep(dependentInclusionCdkStepper)">
            </default-button>
          </div>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="dependentChoosePlanForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div>
            <p class="bold p2">Opções de plano</p>
            <div class="subtitle-spacing">
              <p ngif class="semi-bold p3">Selecione um dos planos disponíveis</p>
              <radio-plan-selection #plan [options]="cctPlans" (selectChange)="selectDependentPlan($event)">
              </radio-plan-selection>

            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(dependentInclusionCdkStepper)">
          </tertiary-button>
          <default-button fxFlex="48" [disabled]="dependentChoosePlanForm.invalid" label="Avançar"
            (onClick)="nextStep(dependentInclusionCdkStepper)">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="fourthStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div>
            <p class="bold p2">Inclusão na fatura</p>
            <div class="subtitle-spacing">

              <ng-container>
                <p ngif class="semi-bold p3">Selecione uma competência</p>
              </ng-container>


              <radio-group #accrualDate (selectChange)="accrualDateValueChanges($event)"
                (optionSelected)="changeSelectedAccrualDate($event)" [options]="radioButtonAccrualDatesOptions"
                fxLayout="column"></radio-group>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(dependentInclusionCdkStepper)">
          </tertiary-button>
          <default-button fxFlex="48" [disabled]="fourthStepForm.invalid" label="Avançar"
            (onClick)="nextStep(dependentInclusionCdkStepper)">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
        <div class="stepper-scroll-content">
          <span-warning disclaimer="Confira as informações:"
            description="após salvar o cadastro, somente será possível editar alguns dados entrando em contato com a gente.">
          </span-warning>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Dados gerais</p>
            <hr class="hr-separator" />
          </div>
          <div class="general-data-info">
            <p class="semi-bold p4 secondary-light-text-color">
              Titular vinculado:
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionSecondStepForm.get("holderName")?.value
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Grau de parentesco com o titular:
              <span class="regular p4 gray-dark-text-color">{{
                getDegreeOfKinshipNameById(dependentInclusionSecondStepForm.get("degreeOfKinship")?.value)
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Data de nascimento:
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionSecondStepForm.get("birthday")?.value
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              CPF:
              <span class="regular p4 gray-dark-text-color">{{
                (this.dependentInclusionThirdStepForm.get("cpf")?.value | cpf) ||
                "-----------"
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Nome completo:
              <span class="regular p4 gray-dark-text-color">
                {{ this.dependentInclusionThirdStepForm.get("fullName")?.value }}
              </span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Nome da mãe:
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionThirdStepForm.get("fullMotherName")?.value
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Sexo:
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionThirdStepForm.get("gender")?.value
                }}</span>
            </p>
          </div>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Contatos</p>
            <hr class="hr-separator" />
          </div>
          <div class="general-data-info">
            <p class="semi-bold p4 secondary-light-text-color">
              Celular (WhatsApp):
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionFourthStepForm.get("mobilePhoneNumber")?.value | phone
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Telefone (opcional):
              <span class="regular p4 gray-dark-text-color">{{
                (this.dependentInclusionFourthStepForm.get("telephoneNumber")?.value | phone) ||
                "(DD) - ---- -----"
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              E-mail (opcional):
              <span class="regular p4 gray-dark-text-color">{{
                (this.dependentInclusionFourthStepForm.get("email")?.value) ||
                "não informado"
                }}</span>
            </p>
          </div>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Plano</p>
            <hr class="hr-separator" />
            <div class="general-data-info">
              <p class="semi-bold p4 secondary-light-text-color">
                {{dependentChoosePlanForm.get('planName')?.value}}
              </p>
              <p class="regular p3 gray-dark-text-color" style="color: #928F8F !important">
                {{ dependentChoosePlanForm.get('planPrice')?.value }} <span style="color: #928F8F"
                  class="regular small">/{{
                  dependentChoosePlanForm.get('planFrequency')?.value }}</span>
              </p>
            </div>
          </div>

          <div class="topic-title">
            <p class="bold p3 section-title-color">Inclusão na fatura</p>
            <hr class="hr-separator" />
          </div>
          <div class="general-data-info">
            <p class="semi-bold p4 secondary-light-text-color">
              {{ selectedAccrualDate?.description }} ({{
              selectedAccrualDate?.label
              }})
            </p>
            <p class="regular p4 subtitle gray-dark-text-color">

              {{ selectedAccrualDate?.subdescription }} {{ dateCanUsePlan || cancellationDate }}
            </p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(dependentInclusionCdkStepper)">
          </tertiary-button>
          <default-button fxFlex="48" [disabled]="onLoading" label="Salvar inclusão"
            (onClick)="saveDependentInclusion()">
          </default-button>
        </div>
      </div>
    </cdk-step>
  </stepper-generic>
</ng-template>

<ng-template #secondInclusionStepper>
  <stepper-generic id="secondInclusionCdkStepper" #secondInclusionCdkStepper [linear]="isLinear">
    <cdk-step>
      <form [formGroup]="dependentInclusionSecondStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="stepper-scroll-content">
            <p class="bold p2">Dados gerais</p>
            <div class="input-container">
              <default-input [disabled]="true" [value]="dependentInclusionSecondStepForm.get('holderName')?.value"
                formControlName="holderName" label="Titular vinculado" placeholder=""></default-input>
            </div>
            <div class="input-container">
              <default-dropdown [options]="degreeOfKinshipOptions" formControlName="degreeOfKinship"
                label="Grau de parentesco com o titular" placeholder="Selecione uma opção"></default-dropdown>
            </div>
            <div class="input-container">
              <default-input (valueChanges)="changeBirthdayDependent($event)" formControlName="birthday"
                label="Data de nascimento" [dropSpecialCharacters]="false" mask="00/00/0000" placeholder="DD/MM/AAAA">
              </default-input>
            </div>
          </div>
          <div fxLayout="row-" fxLayoutAlign="end end">
            <default-button fxFlex="48" [disabled]="dependentInclusionSecondStepForm.invalid" label="Avançar"
              (onClick)="nextStep(secondInclusionCdkStepper)">
            </default-button>
          </div>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="dependentInclusionThirdStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="stepper-scroll-content">
            <p class="bold p2">Dados gerais</p>
            <input [hidden]="true" />
            <div class="input-container">
              <default-input formControlName="cpf" label="CPF" mask="000.000.000-00"
                placeholder="Informe o CPF do dependente"></default-input>
            </div>
            <div fxLayout="column" class="input-container">
              <default-input formControlName="fullName" label="Nome completo"
                placeholder="Informe o nome completo do dependente"></default-input>
            </div>
            <div class="input-container">
              <default-input formControlName="fullMotherName" label="Nome completo da mãe"
                placeholder="Informe o nome completo da mãe do dependente"></default-input>
            </div>
            <div class="input-container">
              <radio-group #gender (selectChange)="dependentGenderValueChanges($event)" title="Sexo"
                [options]="radioButtonGenderOptions" subtitle="(para fins médicos)"></radio-group>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(secondInclusionCdkStepper)">
          </tertiary-button>
          <default-button fxFlex="48" [disabled]="dependentInclusionThirdStepForm.invalid" label="Avançar"
            (onClick)="nextStep(secondInclusionCdkStepper)">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="dependentInclusionFourthStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div class="stepper-scroll-content">
            <p class="bold p2">Contatos</p>
            <input [hidden]="true" />
            <div class="input-container">
              <default-input formControlName="mobilePhoneNumber" label="Celular" informativeLabel="(WhatsApp)"
                mask="(00) 0 0000 0000" placeholder="(DD) - ---- -----"></default-input>
            </div>
            <div class="input-container">
              <default-input formControlName="telephoneNumber" label="Telefone" informativeLabel="(opcional)"
                mask="(00) 0000 0000" placeholder="(DD) - ---- ----"></default-input>
            </div>
            <div class="input-container">
              <default-input informativeLabel="(opcional)" formControlName="email" label="E-mail"
                placeholder="Informe um e-mail">
              </default-input>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between end">
            <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(secondInclusionCdkStepper)">
            </tertiary-button>
            <default-button fxFlex="48" [disabled]="dependentInclusionFourthStepForm.invalid" label="Avançar"
              (onClick)="nextStep(secondInclusionCdkStepper)">
            </default-button>
          </div>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="dependentChoosePlanForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div>
            <p class="bold p2">Opções de plano</p>
            <div class="subtitle-spacing">
              <p ngif class="semi-bold p3">Selecione um dos planos disponíveis</p>
              <radio-plan-selection #plan [options]="cctPlans" (selectChange)="selectDependentPlan($event)">
              </radio-plan-selection>

            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(secondInclusionCdkStepper)">
          </tertiary-button>
          <default-button fxFlex="48" [disabled]="dependentChoosePlanForm.invalid" label="Avançar"
            (onClick)="nextStep(secondInclusionCdkStepper)">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="fourthStepForm" fxLayout="column" fxFlex>
        <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div>
            <p class="bold p2">Inclusão na fatura</p>
            <div class="subtitle-spacing">
              <ng-container *ngIf="this.hasCCTCompany; else elseTemplate">
                <p ngif class="semi-bold p3">Competência para inclusão</p>
              </ng-container>
              <ng-template #elseTemplate>
                <p ngif class="semi-bold p3">Selecione uma competência</p>
              </ng-template>


              <radio-group #accrualDate (selectChange)="accrualDateValueChanges($event)"
                (optionSelected)="changeSelectedAccrualDate($event)" [options]="radioButtonAccrualDatesOptions"
                fxLayout="column"></radio-group>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(secondInclusionCdkStepper)">
          </tertiary-button>
          <default-button fxFlex="48" [disabled]="fourthStepForm.invalid" label="Avançar"
            (onClick)="nextStep(secondInclusionCdkStepper)">
          </default-button>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
        <div class="stepper-scroll-content">
          <span-warning disclaimer="Confira as informações:"
            description="após salvar o cadastro, somente será possível editar alguns dados entrando em contato com a gente.">
          </span-warning>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Dados gerais</p>
            <hr class="hr-separator" />
          </div>
          <div>
            <p class="semi-bold p4 secondary-light-text-color">
              Titular vinculado:
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionSecondStepForm.get("holderName")?.value
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Grau de parentesco com o titular:
              <span class="regular p4 gray-dark-text-color">{{
                getDegreeOfKinshipNameById(dependentInclusionSecondStepForm.get("degreeOfKinship")?.value)
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Data de nascimento:
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionSecondStepForm.get("birthday")?.value
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              CPF:
              <span class="regular p4 gray-dark-text-color">{{
                (this.dependentInclusionThirdStepForm.get("cpf")?.value | cpf) ||
                "-----------"
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Nome completo:
              <span class="regular p4 gray-dark-text-color">
                {{ this.dependentInclusionThirdStepForm.get("fullName")?.value }}
              </span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Nome da mãe:
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionThirdStepForm.get("fullMotherName")?.value
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Sexo:
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionThirdStepForm.get("gender")?.value
                }}</span>
            </p>
          </div>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Contatos</p>
            <hr class="hr-separator" />
          </div>
          <div>
            <p class="semi-bold p4 secondary-light-text-color">
              Celular (WhatsApp):
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionFourthStepForm.get("mobilePhoneNumber")?.value | phone
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              Telefone (opcional):
              <span class="regular p4 gray-dark-text-color">{{
                this.dependentInclusionFourthStepForm.get("telephoneNumber")?.value?
                (this.dependentInclusionFourthStepForm.get("telephoneNumber")?.value | phone) :
                "(DD) - ---- -----"
                }}</span>
            </p>
            <p class="semi-bold p4 secondary-light-text-color">
              E-mail (opcional):
              <span class="regular p4 gray-dark-text-color">{{
                (this.dependentInclusionFourthStepForm.get("email")?.value) ||
                "não informado"
                }}</span>
            </p>
          </div>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Plano</p>
            <hr class="hr-separator" />
            <div>
              <p class="semi-bold p4 secondary-light-text-color">
                {{dependentChoosePlanForm.get('planName')?.value}}
              </p>
              <p class="regular p3 gray-dark-text-color" style="color: #928F8F !important">
                {{ dependentChoosePlanForm.get('planPrice')?.value }} <span style="color: #928F8F"
                  class="regular small">/{{
                  dependentChoosePlanForm.get('planFrequency')?.value }}</span>
              </p>
            </div>
          </div>

          <div class="topic-title">
            <p class="bold p3 section-title-color">Inclusão na fatura</p>
            <hr class="hr-separator" />
          </div>
          <div>
            <p class="semi-bold p4 secondary-light-text-color">
              {{ selectedAccrualDate?.description }} ({{
              selectedAccrualDate?.label
              }})
            </p>
            <p class="regular p4 subtitle gray-dark-text-color">

              {{ selectedAccrualDate?.subdescription }} {{ dateCanUsePlan || cancellationDate }}
            </p>
          </div>
          <div class="topic-title">
            <p class="bold p3 section-title-color">Benefícios</p>
            <hr class="hr-separator" />
          </div>
          <div fxLayout="row" class="benefits-container" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="center center">
              <svg-icon src="../../../assets/svgs/gray-check.svg" class="icon-spacing"></svg-icon>
              <p class="semi-bold small">Agiben Seguros</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <svg-icon src="../../../assets/svgs/gray-check.svg" class="icon-spacing"></svg-icon>
              <p class="semi-bold small">Clin Orto</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <svg-icon src="../../../assets/svgs/gray-check.svg" class="icon-spacing"></svg-icon>
              <p class="semi-bold small">Melhor.ai</p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <tertiary-button fxFlex="48" label="Voltar" (onClick)="previousStep(secondInclusionCdkStepper)">
          </tertiary-button>
          <default-button fxFlex="48" [disabled]="onLoading" label="Salvar inclusão"
            (onClick)="saveDependentInclusion()">
          </default-button>
        </div>
      </div>
    </cdk-step>
  </stepper-generic>
</ng-template>

<ng-template #modalEditContact>
  <form [formGroup]="editContactForm">
    <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none">
      <div>
        <p class="bold p2">Contatos</p>
        <div class="subtitle-spacing">
          <default-input formControlName="email" label="E-mail" placeholder="Digite seu e-mail"></default-input>

          <default-input formControlName="mobilePhone" label="Celular (Whatsapp)" placeholder="Digite seu celular"
            mask="00 00000-0000" [dropSpecialCharacters]="false"></default-input>

          <default-input formControlName="phone" label="Telefone (opcional)" placeholder="Digite seu telefone"
            mask="(00) 0000 0000" placeholder="(DD) ---- -----" [dropSpecialCharacters]="false"></default-input>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end end">
        <default-button class="footer" fxFlex="48" [disabled]="editContactForm.invalid" label="Salvar"
          (onClick)="editContact()"></default-button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #modalCancelConfirmation>
  <div class="modal-step" fxLayout="column" fxLayoutAlign="space-between none">
    <div class="modal-content">
      <div>
        <p class="cancel-title bold">Solicitação de cancelamento</p>
      </div>
      <div fxLayout="column" class="cancel-subtitle">
        <div fxLayout="row" class="justify-content-center">
          <p class="cancel-warning-subtitle">
            <span class="error-text-color bold">Atenção:&nbsp;</span> ao
            cancelar o colaborador, todos os
          </p>
        </div>
        <div>
          <p class="cancel-warning-subtitle">
            dependentes vinculados também são cancelados.
          </p>
        </div>
      </div>
      <div class="user-list-box default-border-radius">
        <div class="cancel-colaborator" fxLayout="column">
          <p class="client bold secondary-text-color">Colaborador</p>
          <p class="client-name">
            {{
            collaboratorsDependentsInfo?.associado?.nome | captalizewords
            }}&nbsp;<span class="gray-dark-text-color">(CPF:
              {{ collaboratorsDependentsInfo?.associado?.cpf | cpf }})</span>
          </p>
        </div>
        <div class="cancel-dependents">
          <p class="client bold secondary-text-color">Dependentes</p>
          <div class="dependents-list" *ngFor="let i of collaboratorsDependentsInfo?.dependentes">
            <p *ngIf="collaboratorsDependentsInfo?.dependentes.length" class="client-name">
              {{ i.nome | captalizewords }}&nbsp;<span class="gray-dark-text-color">(CPF: {{ i.cpf | cpf }})</span>
            </p>
          </div>
          <p *ngIf="!collaboratorsDependentsInfo?.dependentes.length" class="client-name gray-dark-text-color">
            Não há dependentes vinculados
          </p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <default-button class="footer" fxFlex="100" label="Continuar"
        (onClick)="collaboratorActionClicked(collaboratorInfoRow)"></default-button>
    </div>
  </div>
</ng-template>
