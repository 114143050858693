<div class="form-group">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <p class="semi-bold p4 spacing default-input-label" [ngClass]="{ 'disabled-input': disabled }">
      {{ label }} <span class="regular p4 default-input-informative-label">{{ informativeLabel }}</span>
    </p>
    <div *ngIf="capsLockMessage && showCapsLockInfo && type === 'password'">
      <svg-icon src="../../../../assets/svgs/capslock-info.svg"> </svg-icon>
    </div>
  </div>
  <div class="inner-addon right-addon" fxLayout="row" fxLayoutAlign=" center">
    <i (click)="this.hide = !this.hide" *ngIf="type === 'password'" class="svg-container glyphicon">
      <app-svg [focused]="this.focused" [hide]="this.hide" [control]="{
          'is-invalid': ngControl.control?.touched && ngControl.control?.errors
        }"></app-svg>
    </i>
    
    <div *ngIf="activeSuffixIcon || inactiveSuffixIcon" class="input-group">
      <input [thousandSeparator]="thousandSeparator" [prefix]="prefix" [mask]="mask" class="form-control"
      [dropSpecialCharacters]="dropSpecialCharacters" [ngClass]="{
        'is-invalid':
          ngControl.control?.touched && ngControl.control?.errors && !focused
      }" (change)="onChangeValue($event)" [formControl]="ngControl?.control"
      [type]="!hide && type === 'password' ? 'password' : 'text'" placeholder="{{ placeholder }}" #passwordField
      (focusout)="changeFocus($event)" (focus)="changeFocus($event)" />
      
      <div class="input-group-append">
        <svg-icon *ngIf="!activeSuffixState" class='input-group-text' [ngStyle]="{'cursor': disabled ? '' : 'pointer'}" [src]="inactiveSuffixIcon" (click)="iconClicked($event)"></svg-icon>
        <svg-icon *ngIf="activeSuffixState" class='input-group-text' [ngStyle]="{'cursor': 'default'}" [src]="activeSuffixIcon"></svg-icon>
      </div>
    </div>

    <input *ngIf="!activeSuffixIcon && !inactiveSuffixIcon" [thousandSeparator]="thousandSeparator" [prefix]="prefix" [mask]="mask" class="form-control"
      [dropSpecialCharacters]="dropSpecialCharacters" [ngClass]="{
        'is-invalid':
          ngControl.control?.touched && ngControl.control?.errors && !focused
      }" (change)="onChangeValue($event)" [formControl]="ngControl?.control"
      [type]="!hide && type === 'password' ? 'password' : 'text'" placeholder="{{ placeholder }}" #passwordField
      (focusout)="changeFocus($event)" (focus)="changeFocus($event)" />
  </div>

  <div class="is-invalid error-message" *ngIf="ngControl.control?.errors && ngControl.control?.touched && errorMessage">
    <div fxLayout="row" fxLayoutAlign="start center">
      <svg-icon src="../../../../assets/svgs/input-error-icon.svg"></svg-icon>
      <div class="align-error-text">
        <p class="is-invalid semi-bold small">{{errorMessage}}</p>
      </div>
    </div>
  </div>

  <div class="is-invalid error-message"
    *ngIf="ngControl.control?.errors?.required && ngControl.control?.touched && !errorMessage">
    <div fxLayout="row" fxLayoutAlign="start center">
      <svg-icon src="../../../../assets/svgs/input-error-icon.svg"></svg-icon>
      <div class="align-error-text">
        <p class="is-invalid semi-bold small">Campo obrigatório</p>
      </div>
    </div>
  </div>
  <div class="is-invalid error-message"
    *ngIf="ngControl.control?.errors?.fullName && ngControl.control?.touched && !errorMessage">
    <div fxLayout="row" fxLayoutAlign="start center">
      <svg-icon src="../../../../assets/svgs/input-error-icon.svg"></svg-icon>
      <div class="align-error-text">
        <p class="is-invalid semi-bold small">Nome inválido. Tente novamente.</p>
      </div>
    </div>
  </div>
  <div class="is-invalid error-message"
    *ngIf="ngControl.control?.errors?.email && ngControl.control?.touched && !errorMessage">
    <div fxLayout="row" fxLayoutAlign="start center">
      <svg-icon src="../../../../assets/svgs/input-error-icon.svg"></svg-icon>
      <div class="align-error-text">
        <p class="is-invalid semi-bold small">E-mail inválido. Tente novamente.</p>
      </div>
    </div>
  </div>
  <div class="is-invalid error-message" *ngIf="
      !ngControl.control?.errors?.message &&
      ngControl.control?.errors?.mask?.requiredMask === 'CPF_CNPJ' &&
      ngControl.control?.errors?.mask?.actualValue &&
      ngControl.control?.touched && !errorMessage
    ">
    <div fxLayout="row" fxLayoutAlign="start center">
      <svg-icon src="../../../../assets/svgs/input-error-icon.svg"></svg-icon>
      <div>
        <p class="is-invalid semi-bold small">CPF ou CNPJ Inválido</p>
      </div>
    </div>
  </div>

  <div class="is-invalid error-message" *ngIf="
      !ngControl.control?.errors?.message &&
      ngControl.control?.errors?.mask?.requiredMask === '(00) 0 0000-0000' &&
      ngControl.control?.errors?.mask?.actualValue &&
      ngControl.control?.touched && !errorMessage
    ">
    <div fxLayout="row" fxLayoutAlign="start center">
      <svg-icon fxLayout="column" src="../../../../assets/svgs/input-error-icon.svg"></svg-icon>
      <div>
        <p class="is-invalid semi-bold small">Número Inválido. Tente novamente</p>
      </div>
    </div>
  </div>

  <div class="is-invalid error-message" *ngIf="
      ngControl.control?.errors?.mask?.requiredMask !== 'CPF_CNPJ' &&
      ngControl.control?.errors?.mask?.requiredMask !== '(00) 0 0000-0000' &&
      ngControl.control?.errors?.mask?.actualValue &&
      ngControl.control?.touched && !errorMessage
      ">
    <div fxLayout="row" fxLayoutAlign="start center">
      <svg-icon src="../../../../assets/svgs/input-error-icon.svg"></svg-icon>
      <div class="align-error-text">
        <p *ngIf="ngControl.control?.errors?.required" class="is-invalid semi-bold small">Campo obrigatório</p>
      </div>
    </div>
  </div>
  <div class="is-invalid error-message"
    *ngIf="ngControl.control?.errors?.message && ngControl.control?.touched && !errorMessage">
    <div fxLayout="row" fxLayoutAlign="start center">
      <svg-icon src="../../../../assets/svgs/input-error-icon.svg"></svg-icon>
      <div>
        <p *ngIf="ngControl.control?.errors?.message" class="is-invalid semi-bold small">
          {{ ngControl.control?.errors?.message }}
        </p>
      </div>
    </div>
  </div>
</div>
