import { ActionsType } from "../enums/action-type";
import { ReviewTypes } from "../enums/review-types";

export enum Constants {
  HAS_NO_NETWORK = 0,
}

export const actionTypeToReviewType = {
  [ActionsType.INDIVIDUAL_INCLUSION]: ReviewTypes.EVALUATION_INDIVIDUAL_INCLUSION,
  [ActionsType.INCLUDED_BY_SPREADSHEET]: ReviewTypes.EVALUATION_INCLUSION_BY_SPREADSHEET,
  [ActionsType.FINISH_COLABORATOR_INDIVIDUAL_CANCEL]: ReviewTypes.EVALUATION_INDIVIDUAL_CANCELLATION,
  [ActionsType.DOWNLOAD_BANK_SLIP]: ReviewTypes.EVALUATION_FINANCIAL_DOWNLOAD_PAYMENT_SLIP,
  [ActionsType.CHECK_INVOICE]: ReviewTypes.EVALUATION_FINANCIAL_CHECK_INVOICE,
  [ActionsType.ACCESS_INVOICE_HISTORY_TAB]: ReviewTypes.EVALUATION_FINANCIAL_HISTORIC_INVOICE,
  [ActionsType.PLATFORM_ACCESS]: ReviewTypes.EVALUATION_CSAT,
}
