import { AbstractControl } from '@angular/forms';
import { containsNumbers, containsUppercase } from '../utils/utils';
import { Validators } from '@angular/forms';

export class CustomValidators {
  static cpfLength = 11;
  static cnpjLength = 14;

  static cnpjValidation(value: string) {
    if (!value) return false;

    const isString = typeof value === 'string';
    const validTypes =
      isString || Number.isInteger(value) || Array.isArray(value);

    if (!validTypes) return false;

    if (isString) {
      if (value.length > 18) return false;

      const digitsOnly = /^\d{14}$/.test(value);
      const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

      if (digitsOnly || validFormat) true;
      else return false;
    }

    const match = value.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    if (numbers.length !== 14) return false;

    const items = [...new Set(numbers)];
    if (items.length === 1) return false;

    const calc = (x: any) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const digits = numbers.slice(12);

    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    const digit1 = calc(13);
    return digit1 === digits[1];
  }

  static cpfValidation(value: string) {
    if (!value) return false;

    const validTypes =
      typeof value === 'string' ||
      Number.isInteger(value) ||
      Array.isArray(value);

    if (!validTypes) return false;

    const numbers = value.toString().match(/\d/g)?.map(Number);

    if (numbers?.length !== 11) return false;

    const items = [...new Set(numbers)];
    if (items.length === 1) return false;

    const base = numbers?.slice(0, 9);
    const digits = numbers?.slice(9);

    const calc = (n: any, i: any, x: any) => n * (x - i);

    const sum = (r: any, n: any) => r + n;

    const digit = (n: any) => {
      const rest = n % numbers?.length;
      return rest < 2 ? 0 : numbers?.length - rest;
    };

    const calc0 = base
      ?.map((n: any, i: any) => calc(n, i, numbers?.length - 1))
      .reduce(sum, 0);
    const digit0 = digit(calc0);

    if (digit0 !== digits[0]) return false;

    const calc1 = base
      .concat(digit0)
      .map((n, i) => calc(n, i, numbers?.length))
      .reduce(sum, 0);
    const digit1 = digit(calc1);
    return digit1 === digits[1];
  }

  static ValidateCPFCNPJ(control: AbstractControl) {
    const cpfCnpj = control.value?.replace(/\D/g, '');
    if (cpfCnpj) {
      if (
        cpfCnpj?.length !== CustomValidators.cpfLength &&
        cpfCnpj?.length !== CustomValidators.cnpjLength
      ) {
        return { message: 'CPF ou CNPJ Inválido' };
      }

      if (cpfCnpj?.length === CustomValidators.cpfLength) {
        if (!CustomValidators.cpfValidation(cpfCnpj)) {
          return { message: 'CPF ou CNPJ Inválido' };
        }
      }

      if (cpfCnpj?.length === CustomValidators.cnpjLength) {
        if (!CustomValidators.cnpjValidation(cpfCnpj)) {
          return { message: 'CPF ou CNPJ Inválido' };
        }
      }
    }
    return null;
  }

  static ValidatePassword(control: AbstractControl) {
    const password = control.value;

    if (!containsNumbers(password)) {
      return { message: '' };
    }
    if (!containsUppercase(password)) {
      return { message: '' };
    }
    return null;
  }

  static ValidateFullName(control: AbstractControl) {
    const fullName: string = control.value?.trim() || '';

    const names = fullName.split(' ');

    if (names.length < 2 && fullName.length > 0) {
      return { fullName: true };
    }

    return null;
  }

  static ValidateBirthday(control: AbstractControl, yearsAgo: number = 100) {
    const date: Date = control.value;
    const today = new Date();
    const minDate = new Date(today.getFullYear() - yearsAgo, 0, 1);
    const maxDate = new Date(today.getFullYear() - 1, 11, 31);

    if (date >= minDate && date <= maxDate) {
      return null;
    }

    return { message: '' };
  }

  static ValidateGender(control: AbstractControl) {
    const gender: string = control.value;
    if (gender === 'MASCULINO' || gender === 'FEMININO') {
      return null;
    }

    return { message: '' };
  }

  static ValidateMobilePhoneNumber(control: AbstractControl) {
    const mobilePhoneNumber: string = control.value;

    const regex = /^\(?\d{2}\)?[- ]?\d{5}[- ]?\d{4}$/;

    if (!control.hasValidator(Validators.required) && !mobilePhoneNumber) {
      return null;
    }

    if (regex.test(mobilePhoneNumber)) {
      return null;
    }
    return { message: '' };
  }

  static ValidateTelephoneNumber(control: AbstractControl) {
    const telephoneNumber: string = control.value;
    const regex = /^\(?\d{2}\)?[- ]?\d{4}[- ]?\d{4}$/;
    if (regex.test(telephoneNumber)) {
      return null;
    }
    return { message: '' };
  }
}
